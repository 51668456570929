import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AssayService } from '../../assay.service';
import { ResultInterval } from '../../../../interfaces/assay.interface';

@Component({
  selector: 'app-semi-quantitative-result-input',
  templateUrl: './semi-quantitative-result-input.component.html',
  styleUrls: ['./semi-quantitative-result-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SemiQuantitativeResultInputComponent),
      multi: true,
    },
  ],
})
export class SemiQuantitativeResultInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('input', { static: false })
  input: ElementRef;

  @ViewChild('semiQuantitativeResultInputWrapper', { static: false })
  cmpWrapper: ElementRef;

  // This is a'shared' input with the validator to get the initial state.
  // tslint:disable-next-line:no-input-rename
  @Input('appSemiQuantitativeValue') initialValue;

  @Input()
  val = '';

  @Input()
  placeholder = '';

  @Input()
  intervals: ResultInterval[] = [];

  @Input()
  hidden = true;

  @Input()
  tabindex = 1;

  @Input()
  disabled = false;

  @Input() readonly = false;

  @Input()
  name;

  @Output()
  lostFocus = new EventEmitter<true>();

  @Output()
  resultChanged = new EventEmitter<true>();

  @Input()
  repeatRequested: boolean;

  @Input()
  noResult: boolean;
  @Output()
  noResultChange = new EventEmitter<boolean>();

  showError = false;

  control: AbstractControl;
  valueSub: Subscription;
  controlStatus: string;
  controlValueWithinLimits = true;

  onChange: any = () => {
    // empty on purpose
  };

  onTouched: any = () => {
    // empty on purpose
  };

  get value() {
    return this.val;
  }

  set value(val: string) {
    this.val = val;
    if (this.val) {
      this.noResult = false;
      this.noResultChange.emit(this.noResult);
    }
    this.onChange(this.val);
    this.onTouched();
  }

  constructor(
    private assayService: AssayService,
    private translate: TranslateService,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    const model = this.injector.get(NgControl);
    this.control = model.control;

    this.valueSub = this.control.valueChanges.subscribe((value: string) => {
      this.controlStatus = this.control.status;

      this.setErrorState();
    });

    // This updates the display value when we have an assay with a saved value. This normally only happens on blur events
    // but the user should see the interval when the assay is loaded, not the entered number.
    if (this.initialValue && this.initialValue !== '') {
      setTimeout(() => {
        this.updateDisplay(this.initialValue);
      });
    }
  }

  ngOnDestroy() {
    if (this.valueSub) {
      this.valueSub.unsubscribe();
    }
  }

  writeValue(value: string) {
    if (value !== this.value) {
      this.value = value;
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = onTouch;
  }

  setErrorState() {
    setTimeout(() => {
      const isInvalid = (this.controlStatus === 'INVALID' || !this.controlValueWithinLimits) && !this.repeatRequested;

      if (isInvalid) {
        let errors: ValidationErrors;
        errors = this.control.errors?.error ? { inputError: true } : { outOfRange: true };
        this.control?.setErrors(errors);
        this.control.markAsDirty();
      } else {
        this.control?.setErrors(null);
      }
    }, 0);
  }

  focusInput() {
    this.input.nativeElement.focus();
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  handleInput($event) {
    let val = $event.target.value.trim();

    if (val.indexOf(',') !== -1) {
      val = val.replace(',', '.');
    }
    this.writeValue(val);
    this.updateDisplay(val, false);
  }

  handleEnter($event) {
    if (this.disabled || this.readonly) {
      return;
    }

    if (!this.showError) {
      this.handleBlur($event);
      this.resultChanged.emit(true);
    }
  }

  handleBackspace() {
    if (this.disabled || this.readonly) {
      return;
    }

    let clear = false;
    const currentValue = this.input.nativeElement.value;

    this.intervals.forEach((interval) => {
      if (currentValue === interval.customerFacingText) {
        clear = true;
      }
    });

    if (clear) {
      if (this.value) {
        this.writeValue('');
      }
    }
  }

  handleFocusOut() {
    setTimeout(() => {
      if (document.activeElement === document.body || !this.cmpWrapper.nativeElement.contains(document.activeElement)) {
        this.lostFocus.emit(true);
      }
    }, 0);
  }

  handleBlur($event) {
    this.updateDisplay($event.target.value);
  }

  updateDisplay(currentValue, updateDisplayResult = true) {
    if (!currentValue) {
      this.showError = false;
      this.controlValueWithinLimits = true;
      this.setErrorState();
      return;
    }
    if (this.control.errors) {
      return;
    }

    if ((currentValue.noResult || this.noResult || this.repeatRequested) && updateDisplayResult) {
      this.input.nativeElement.value = '';
      return;
    }

    if (currentValue.indexOf(',') !== -1) {
      currentValue = currentValue.replace(',', '.');
    }

    // Prevent getRangeDisplayByCount from being called multiple times when nativeElement.value is updated
    let readyToUpdate = true;

    this.intervals.forEach((interval) => {
      if (interval.customerFacingText === currentValue) {
        readyToUpdate = false;
      }
    });

    if (readyToUpdate) {
      const displayResult = this.assayService.getRangeDisplayByCount(this.intervals, currentValue);

      if (displayResult === '') {
        this.showError = true;
        this.controlValueWithinLimits = false;
        this.setErrorState();
      } else {
        this.showError = false;
        this.controlValueWithinLimits = true;
      }

      if (updateDisplayResult) {
        this.input.nativeElement.value = displayResult;
      }
    }
  }
}
