import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Assay } from '../../interfaces/assay.interface';
import { map, switchMap } from 'rxjs/operators';
import { AppStateService } from '../../app-state.service';

@Injectable({
  providedIn: 'root',
})
export class BulkActionsService {
  constructor(private appState: AppStateService) {}

  private _bulkActionItems$ = new BehaviorSubject<{ [key: string]: Assay }>({});
  bulkActionItems$ = this._bulkActionItems$
    .asObservable()
    .pipe(map((assays) => Object.values(assays).filter((assay) => assay)));

  get bulkActionItems(): Assay[] {
    return Object.values(this._bulkActionItems$.value).filter((assay) => assay);
  }

  bulkActionItem(assay: Assay) {
    const currentBulkActionItems = this._bulkActionItems$.value;
    currentBulkActionItems[assay.testCode] = assay;
    this._bulkActionItems$.next(currentBulkActionItems);
  }

  bulkActionDeselect(assay: Assay) {
    const currentBulkActionItems = this._bulkActionItems$.value;
    currentBulkActionItems[assay.testCode] = null;
    this._bulkActionItems$.next(currentBulkActionItems);
  }

  deselectAllBulkActionItems() {
    this._bulkActionItems$.next({});
  }

  refreshBulkActionItems() {
    const currentBulkSelectedAssays = this._bulkActionItems$.value;
    this._bulkActionItems$.next(currentBulkSelectedAssays);
  }

  currentAssayWithinBulkActionItems$ = this.appState.currentAssaySub.pipe(
    switchMap((currentAssay) => this._bulkActionItems$.pipe(map((bulkActionItems) => [currentAssay, bulkActionItems]))),
    map(([currentAssay, bulkSelectedAssays]) =>
      currentAssay ? !!bulkSelectedAssays[currentAssay.assay.testCode] : false
    )
  );

  bulkActionRepeatDisabled$ = this.bulkActionItems$.pipe(
    map((bulkActionItems) =>
      bulkActionItems.some((assay) => !assay.canRepeat || assay.repeatRequested || !assay.canModify)
    )
  );

  bulkActionNoResultDisabled$ = this.bulkActionItems$.pipe(
    map((bulkActionItems) => bulkActionItems.some((assay) => assay?.updatedResult?.noResult || !assay.canModify))
  );

  get bulkActionNoResultDisabled() {
    return this.bulkActionItems.some((assay) => assay?.updatedResult?.noResult || !assay.canModify);
  }

  get bulkActionRepeatDisabled() {
    return this.bulkActionItems.some((assay) => !assay.canRepeat || assay.repeatRequested || !assay.canModify);
  }

  bulkActionCanModifyAssay$ = this.bulkActionItems$.pipe(
    map((bulkActionItems) => bulkActionItems.every((assay) => assay.canModify))
  );

  get bulkSelectedAssaysCanModify() {
    return this.bulkActionItems.every((assay) => assay.canModify);
  }

  get currentAssayWithinBulkActionItems(): boolean {
    return this.bulkActionItems.some((assay) => assay.testCode === this.appState.currentAssay.assay.testCode);
  }

  get commentsDataSource(): string {
    return this.appState.commentsDataSource.href;
  }
}
