<div id="bulk-actions">
  <div id="bulk-action-items-list" data-cy="bulk-action-items-list" luxLayout="column">
    <div
      luxFlex
      luxLayout="row"
      class="bulk-action-item"
      data-cy="bulk-action-item"
      *ngFor="let assay of bulkActionItems$ | async; let i = index">
      <span luxFlex="90%" data-cy="bulk-action-item-testcode" class="testcode">{{ assay.testCode }}</span>

      <a
        #removeBulkActionItem
        class="remove-bulk-action-item"
        href="javascript:void(0)"
        [attr.data.assay-test-code]="assay.testCode"
        (keydown.enter)="deselectBulkActionItem(assay)"
        (click)="deselectBulkActionItem(assay, $event)"
        data-cy="remove-bulk-action-item"
        luxFlex="10%">
        <svg class="spot-icon" aria-labelledby="title">
          <title></title>
          <use xlink:href="/assets/icons/spot_icons.svg#delete"></use>
        </svg>
      </a>
    </div>
  </div>

  <div id="bulk-actions-wrapper">
    <div
      luxLayout="row"
      id="bulk-actions-buttons-wrapper"
      data-cy="bulk-actions-buttons-wrapper"
      luxLayoutAlign="end center"
      luxLayoutGap="20px">
      <button
        id="bulk-action-repeat"
        data-cy="bulk-action-repeat-button"
        [disabled]="bulkActionRepeatDisabled$ | async"
        (mousedown)="bulkActionRepeat($event)"
        (keydown.enter)="bulkActionRepeat($event)"
        title="{{ 'LABELS.REPEAT' | translate }}"
        class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon">
        <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
          <title>{{ 'LABELS.REPEAT' | translate }}</title>
          <use xlink:href="./assets/icons/spot_icons.svg#refresh-redo"></use>
        </svg>
      </button>
      <button
        id="bulk-action-no-result"
        data-cy="bulk-action-no-result-button"
        [disabled]="bulkActionNoResultDisabled$ | async"
        (click)="bulkActionNoResult($event)"
        title="{{ 'LABELS.NO_RESULT' | translate }}"
        class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon">
        <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
          <title>{{ 'LABELS.NO_RESULT' | translate }}</title>
          <use xlink:href="./assets/icons/spot_icons.svg#blocked"></use>
        </svg>
      </button>
      <div class="deselect-all-wrapper">
        <button
          id="deselect-all-button"
          #deselectAllButton
          data-cy="deselect-all-button"
          (click)="deselectAllBulkActionItems()"
          title="{{ 'BUTTONS.DESELECT_ALL' | translate }}"
          class="spot-button spot-button--secondary">
          {{ 'BUTTONS.DESELECT_ALL' | translate }}
        </button>
      </div>
    </div>
    <div luxLayout="row">
      <lux-comments
        luxFlex
        [lab]="lab"
        [allowAdd]="bulkActionCanModifyAssay$ | async"
        [commentsDataSource]="assayCommentsDataSource"
        (addComment)="onAddCommentBulk($event)"
        [placeholder]="'LABELS.COMMENTS_SEARCH' | translate"
        [expandable]="true"
        data-cy="batch-comments"
        #resultBulkComment></lux-comments>
    </div>
  </div>
</div>
