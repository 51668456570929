import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { DefinedTextValue, ShortCodeMapping } from '@lims-common-ux/lux';
import { AssayService } from '../../assay.service';

export interface DefinedTextNumericValue {
  typeCode: string;
  number: string;
  noneSeen?: boolean;
}

export enum DefinedTextNumericError {
  NO_MATCHING_OBSERVATIONS,
  QUANTIFICATION_REQUIRED,
  VALUE_REQUIRED,
}

/**
 * This component handles input where there are observations with mandatory percentage quantifications. The format of the input is
 * <SHORT_CODE><AMOUNT_SEEN> where the short code is defined by the input `shortCodes` and amountSeen is an integer
 * value.
 */
@Component({
  selector: 'app-defined-text-numeric-combo',
  templateUrl: './defined-text-numeric-combo.component.html',
  styleUrls: ['./defined-text-numeric-combo.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DefinedTextNumericComboComponent),
      multi: true,
    },
  ],
})
export class DefinedTextNumericComboComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input()
  resultOptions: DefinedTextValue[] = [];

  @Input()
  placeholder = '';

  _noResult = false;

  @Input()
  set noResult(val: boolean) {
    this._noResult = val;

    if (this._noResult && this.input) {
      requestAnimationFrame(() => {
        this.assignError(null);
        this.input.nativeElement.value = '';
        this.onChange(null);
      });
    }
  }

  get noResult() {
    return this._noResult;
  }

  @Output()
  noResultChange = new EventEmitter<boolean>();
  @Output()
  noneSeen = new EventEmitter();

  @Input()
  hidden = true;

  @Input()
  tabindex = 1;

  // tslint:disable-next-line:no-input-rename
  @Input('value')
  val: DefinedTextNumericValue[] = null;

  @Input()
  initialValue: DefinedTextNumericValue[] = null;

  @Input()
  name;

  @Input()
  shortCodes: ShortCodeMapping;

  @Input()
  showPrefix = false;

  @Input()
  disabled: boolean;

  @Input() readonly: boolean = false;

  @ViewChild('resultInput', { static: false })
  input: ElementRef;

  @ViewChild('wrapper', { static: false })
  cmpWrapper: ElementRef;

  @ViewChildren('resultsListItem')
  resultsListItems!: QueryList<ElementRef>;

  @ViewChildren('deleteIcons')
  deleteIcons!: QueryList<ElementRef>;

  filteredResultOptions: DefinedTextValue[] = [];
  amountSeen: string;
  control: AbstractControl;
  initialFocus = false;
  shouldNotOpenResultsDropdown = false;

  @Input()
  editMode = false;

  @Input()
  repeatRequested: boolean;

  // Helps the template decide what error message to display
  displayError: DefinedTextNumericError = null;

  // Just here to expose the enum to the template
  get errorTypes(): typeof DefinedTextNumericError {
    return DefinedTextNumericError;
  }

  get value() {
    return this.val;
  }

  set value(val: DefinedTextNumericValue[]) {
    // Values returned from the backend (after save, etc.) need a flag set
    if (val && val.length > 0) {
      val.forEach((value) => {
        this.resultOptions.forEach((option) => {
          if (option.code === value.typeCode) {
            value.noneSeen = option.noneSeen;
          }
        });
      });
    }

    this.val = val;

    if (this.val?.length > 0) {
      this.noResult = false;
      this.noResultChange.emit(this.noResult);
    }
    this.updateDisplay();
  }

  constructor(
    private assayService: AssayService,
    private injector: Injector,
    @Inject('Document')
    private document: Document
  ) {}

  ngOnInit() {
    const model = this.injector.get(NgControl);
    this.control = model.control;
    this.filteredResultOptions = this.resultOptions;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes?.noResult?.currentValue || changes?.repeatRequested?.currentValue) && this.input) {
      setTimeout(() => {
        this.resetErrorState();
        this.input.nativeElement.value = '';
      }, 0);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onChange: any = () => {
    // empty on purpose
  };

  onTouched: any = () => {
    // empty on purpose
  };

  writeValue(value: DefinedTextNumericValue | DefinedTextNumericValue[]) {
    // handles no result rewrite
    if (!value && this.value) {
      this.value = null;
      return;
    }

    // True when the assay has existing values and form is populating the first time.
    if (value && Array.isArray(value)) {
      this.value = value;
      return;
    }

    if (value) {
      const existing = this.value ? this.value : [];
      const newVal = value as DefinedTextNumericValue;
      if (!newVal.noneSeen) {
        // we filter out any existing values that match the new value we also remove any existing None Seen
        const filteredVal = existing.filter((existingValue: DefinedTextNumericValue) => {
          return newVal.typeCode !== existingValue.typeCode && !existingValue.noneSeen;
        });
        filteredVal.unshift(newVal);
        this.value = filteredVal;
      } else {
        this.value = [newVal];
      }
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = onTouch;
  }

  close($event = null) {
    if (!this.hidden && $event) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
    }
    this.hidden = true;
  }

  open() {
    this.hidden = false;
  }

  isClosed() {
    return this.hidden;
  }

  focusInput(shouldNotOpenResultsDropdown = false) {
    this.shouldNotOpenResultsDropdown = shouldNotOpenResultsDropdown;
    this.input.nativeElement.focus();
  }

  verifyCompleteEntry() {
    if (this.input.nativeElement.value) {
      this.input.nativeElement.click();

      requestAnimationFrame(() => {
        this.initialFocus = false;
        this.close();
        this.assignError(DefinedTextNumericError.VALUE_REQUIRED);
      });
    }
  }

  handleFocusOut($event) {
    this.onTouched();
    if (this.control.dirty && !this.control.errors) {
      this.onChange(this.value);
    }
    setTimeout(() => {
      if (document.activeElement === document.body || !this.cmpWrapper.nativeElement.contains(document.activeElement)) {
        this.initialFocus = false;
        this.close();
      }
    }, 0);
  }

  // alt + e or double clicking puts this component into edit mode
  // which allows us to remove Result values
  toggleEditMode($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    if (this.disabled || this.readonly) {
      return;
    }
    this.editMode = !this.editMode;
    if (!this.editMode) {
      this.focusInput();
    }
  }

  /**
   * This is responsible for setting several error states while the user is entering text into the input field. We
   * do this here instead of a validator because the model does not change until a value has been selected, and these
   * are all presented before that happens.
   *
   * VALUE_REQUIRED, if the user has removed all the saved values and searching for another result, this error should be
   * set until a value is selected.
   * NO_MATCHING_OBSERVATIONS, when the user has entered text into the input, but no short codes match what was entered.
   * QUANTIFICATION_NOT_ALLOWED, when the user enters a valid short code and an amount seen for an option that does not
   * have any intervals defined for it.
   */
  handleInput($event) {
    this.onTouched();
    const value = $event.target.value.trim().toUpperCase();
    this.filteredResultOptions = [];
    this.amountSeen = null;
    // clear errors, they are all verified after input is parsed.
    this.assignError(null);

    if (!value || value?.trim() === '') {
      this.filteredResultOptions = this.resultOptions;
    } else {
      let selectedShortCode;

      selectedShortCode = this.findShortCode(value);
      this.amountSeen = this.getAmountSeen(value, selectedShortCode);
      this.filteredResultOptions = this.optionsForShortCode(selectedShortCode);
    }

    if (!this.filteredResultOptions[0]?.noneSeen) {
      this.verifyInput();
    }

    // VALUE_REQUIRED shouldn't prevent the list from displaying as the user is likely changing/adding inputs at
    // this point.
    if (this.displayError === null || this.displayError === DefinedTextNumericError.VALUE_REQUIRED) {
      this.open();
    } else {
      this.close();
    }
  }

  private verifyInput() {
    if (this.filteredResultOptions.length === 0) {
      this.assignError(DefinedTextNumericError.NO_MATCHING_OBSERVATIONS);
    } else if (this.isInvalidAmountSeen()) {
      this.assignError(DefinedTextNumericError.QUANTIFICATION_REQUIRED);
    }
  }

  /**
   * @return true if the value is set and is NOT a valid integer. if the number is less then zero, an invalid character,
   * or a number we dont like (1.0) it is considered invalid
   */
  private isInvalidAmountSeen(): boolean {
    return this.amountSeen !== null && this.toNormalizedNumericValue(this.amountSeen) === null;
  }

  private getAmountSeen(value: string, selectedShortCode: string): string {
    let amountSeen = null;

    // confirm there was something after the shortcode
    if (selectedShortCode != null && value.length > selectedShortCode.length) {
      // get a string representation of the remaining characters
      amountSeen = value.substring(selectedShortCode.length, selectedShortCode.length + value.length);
    }

    return amountSeen;
  }

  private findShortCode(enteredText): string | null {
    let code: string = null;

    for (const shortCode in this.shortCodes) {
      // absolute match on shortcode with no quantification to support shortcodes like '0' for NONE_SEEN
      if (shortCode === enteredText) {
        code = shortCode;
        break;
      } else {
        // Match shortcode and quantification combos
        const enteredShortCode = enteredText.replaceAll(/[<>,.\d]/g, '');
        if (shortCode === enteredShortCode) {
          code = shortCode;
          break;
        }
      }
    }
    return code;
  }

  assignError(error: DefinedTextNumericError | null) {
    const newErrorState = {
      inputError: error === DefinedTextNumericError.NO_MATCHING_OBSERVATIONS,
      removedSavedValues: error === DefinedTextNumericError.VALUE_REQUIRED,
      quantificationRequired: error === DefinedTextNumericError.QUANTIFICATION_REQUIRED,
    };

    if (error === null) {
      this.control.setErrors(null);
    } else {
      this.control.setErrors(newErrorState);
    }
    this.displayError = error;
  }

  resetErrorState() {
    requestAnimationFrame(() => {
      this.assignError(null);
    });
  }

  handleEnter($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.disabled || this.readonly) {
      return;
    }

    if (this.isClosed() && !this.input.nativeElement.value) {
      this.filteredResultOptions = this.resultOptions;
      this.open();
    } else if (!this.isClosed()) {
      if (this.filteredResultOptions.length) {
        this.selectOption(this.filteredResultOptions[0], $event);
      } else {
        this.close();
      }
    } else if (this.isClosed() && this.input.nativeElement.value && $event?.target?.value && this.control.errors) {
      setTimeout(() => {
        this.displayError = null;
        this.control.setErrors(null);

        setTimeout(() => {
          this.handleInput($event);
        }, 0);
      }, 0);
    }
  }

  handleFocus($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (!this.initialFocus) {
      this.initialFocus = true;
    }

    if (
      !this.value?.length &&
      this.initialFocus &&
      !this.control.errors &&
      !this.disabled &&
      !this.readonly &&
      !this.shouldNotOpenResultsDropdown
    ) {
      this.open();
    }

    this.shouldNotOpenResultsDropdown = false;
  }

  inputHasFocus() {
    return this.input?.nativeElement === this.document.activeElement;
  }

  handleArrowDown($event) {
    if (!this.isClosed()) {
      $event.preventDefault();
      $event.stopImmediatePropagation();

      this.filteredResultOptions.push(this.filteredResultOptions.splice(0, 1)[0]);

      return false;
    }
  }

  removeResultValue(index: number) {
    if (!this.editMode) {
      return;
    }

    this.value.splice(index, 1);
    if (!this.value.length) {
      this.value = null;
    }

    if (this.checkIfValueRequired()) {
      this.assignError(DefinedTextNumericError.VALUE_REQUIRED);
    } else {
      this.onChange(this.value);
      if (this.value === null || this.value?.length === 0) {
        this.control.markAsPristine();
      }
    }

    // we want focus to be on next Result Value, otherwise set focus to the input when removing
    setTimeout(() => {
      let nextEleToFocus: ElementRef;
      if (!this.value?.length) {
        nextEleToFocus = this.input;
      } else {
        const nextIndex = index === this.value.length ? this.value.length - 1 : index;
        nextEleToFocus = this.deleteIcons.toArray()[nextIndex];
      }

      nextEleToFocus.nativeElement.focus();
    }, 0);
  }

  private checkIfValueRequired(): boolean {
    return !this.value?.length && this.initialValue?.length && !this.noResult;
  }

  handleArrowUp($event) {
    if (!this.isClosed()) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
      const lastItem: DefinedTextValue = this.filteredResultOptions.pop();
      this.filteredResultOptions.unshift(lastItem);
    }
  }

  getShortCodeByValue(val: string) {
    let prop;
    let match = '';

    for (prop in this.shortCodes) {
      if (val === this.shortCodes[prop]) {
        match = prop;

        break;
      }
    }

    return match;
  }

  selectOption(definedValue: DefinedTextValue, $event: Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    if (this.disabled || this.readonly) {
      return;
    }
    this.editMode = false;

    const normalizedAmountSeen = this.toNormalizedNumericValue(this.amountSeen);

    if (!this.amountSeen && !normalizedAmountSeen && !definedValue.noneSeen) {
      this.focusInput();
      let inputVal = this.input.nativeElement.value;
      if (inputVal === '') {
        inputVal = this.getShortCodeByValue(definedValue.code);
      }
      this.input.nativeElement.value = inputVal;
      this.assignError(DefinedTextNumericError.QUANTIFICATION_REQUIRED);
      this.close();
      return;
    } else {
      this.writeValue({
        typeCode: definedValue.code,
        noneSeen: definedValue.noneSeen,
        number: definedValue.noneSeen ? null : this.removeTrailingDecimalSeparators(normalizedAmountSeen),
      });
    }

    this.amountSeen = null;
    this.close();
    this.updateDisplay();
    this.filteredResultOptions = this.resultOptions;
    this.focusInput();
    this.assignError(null);
    this.onChange(this.value);

    if (this.value[0].noneSeen) {
      this.noneSeen.emit();
    }
  }

  getObservationDisplayTextByValue(value: string): string {
    return this.assayService.getObservationDisplayTextByValue(this.resultOptions, value);
  }

  handleSpace($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    return false;
  }

  private updateDisplay() {
    this.input.nativeElement.value = '';
    this.control.setErrors(null);
  }

  private optionsForShortCode(selectedShortCode): DefinedTextValue[] {
    return this.resultOptions.filter((definedText) => {
      return definedText.code === this.shortCodes[selectedShortCode];
    });
  }

  toNormalizedNumericValue(value: string): string {
    const [, inequality, whole, separator, fractional] = /^([<>])?(\d*)([,.])?(\d*)$/.exec(value) || [
      null,
      null,
      null,
      null,
      null,
    ];

    if (!whole && !fractional) {
      return null;
    }
    const untilNonZero = (str, character) => str + (str.length > 0 || character !== '0' ? character : '');
    const removeLeadingZeros = (str) => Array.prototype.reduce.call(str, untilNonZero, '');

    return (
      (inequality ? inequality : '') + (removeLeadingZeros(whole) || '0') + (fractional ? separator + fractional : '')
    );
  }

  removeTrailingDecimalSeparators(val: string) {
    if (val) {
      const lastCharacter = val[val.length - 1];

      if (val.length > 1 && (lastCharacter === ',' || lastCharacter === '.')) {
        val = val.substring(0, val.length - 1);
      }
    }

    return val;
  }

  handleClick(event) {
    if (document.activeElement === this.input.nativeElement && this.hidden) {
      this.handleFocus(event);
    }
  }
}
